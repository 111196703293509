import "../../scss/components/customSwiper.scss";

import Swiper from "swiper/bundle";

const breakpointsType1 = {
  320: {
    slidesPerView: 1,
    spaceBetween: 20,
  },
  768: {
    slidesPerView: 2,
    spaceBetween: 30,
  },
  992: {
    slidesPerView: 3,
    spaceBetween: 30,
  },
};
const breakpointsType2 = {
  320: {
    slidesPerView: 1,
    spaceBetween: 20,
  },
  576: {
    slidesPerView: 2,
    spaceBetween: 20,
  },
  992: {
    slidesPerView: 3,
    spaceBetween: 25,
  },
  1400: {
    slidesPerView: 4,
    spaceBetween: 30,
  },
};
const breakpointsType3 = {
  320: {
    slidesPerView: 1,
    spaceBetween: 0,
  },
};
const breakpointsType4 = {
  320: {
    slidesPerView: 1,
    spaceBetween: 20,
  },
  576: {
    slidesPerView: 2.2,
    spaceBetween: 20,
  },
  992: {
    slidesPerView: 3.2,
    spaceBetween: 25,
  },
  1400: {
    slidesPerView: 4.2,
    spaceBetween: 15,
  },
};

const breakpointsTypes = {
  type1: breakpointsType1,
  type2: breakpointsType2,
  type3: breakpointsType3,
  type4: breakpointsType4,
};
const spaceForTypes = { type1: 20, type2: 20, type3: 0 };

window.setUpSliders = function setUpSliders(selector) {
  const articleSliders = document.querySelectorAll(selector);
  for (var i = 0; i < articleSliders.length; i++) {
    var parent = document.getElementById(articleSliders[i].id);
    const prefix = parent.getAttribute("data-prefix");
    const breakpointType = parent.getAttribute("data-breakpoint");
    parent
      .querySelector("." + prefix + "-swiper")
      .classList.add(prefix + "-swiper-" + i);
    parent
      .querySelector(".swiper-button-next")
      .classList.add(prefix + "-swiper-button-next-" + i);
    parent
      .querySelector(".swiper-button-prev")
      .classList.add(prefix + "-swiper-button-prev-" + i);
    new Swiper("." + prefix + "-swiper-" + i, {
      loop: false,
      speed: 400,
      observer: true,
      observeParents: true,
      spaceBetween: breakpointsTypes[breakpointType],
      slidesPerView: 1,
      navigation: {
        nextEl: "." + prefix + "-swiper-button-next-" + i,
        prevEl: "." + prefix + "-swiper-button-prev-" + i,
      },
      breakpoints: breakpointsTypes[breakpointType],
    });
  }
};

setUpSliders(".swiper-container");
