import "../../scss/shortcodes/missions.scss";
import {tabsHandler} from "../components/tabs";

document.addEventListener("DOMContentLoaded", function(event) {
    let tabContainers = document.querySelectorAll(".missions-container");
    tabContainers.forEach((tabContainer) => {
        let tabButtons = tabContainer.querySelectorAll(".missions-tab");
        let tabContent = tabContainer.querySelectorAll(".tab-content");
        tabsHandler(tabButtons,tabContent);
    });
});