import "../../scss/components/popup.scss";

export function showPopupAlertModal() {
	const popup = document.getElementById("popup_alert_modal");
	if(popup === null)
		return;
	document.body.classList.add('modal-open');
	popup.classList.add('show');
	popup.style.display = "block";
	document.body.insertAdjacentHTML("beforeend", "<div class=\"modal-backdrop fade show\"></div>");

	const popupContainer = popup.querySelector('#popup_alert_modal .modal-content');
	const popupContent = popup.querySelector('#popup_alert_modal .modal-real-content');
	const popupBullets = popup.querySelector('#popup_alert_modal .modal-real-bullets');
	const popupButton = popup.querySelector('#popup_alert_modal .btn_in_modal');
	const popupImage = popup.querySelector('#popup_alert_modal img.img-fluid');

	// Delay and show the container
	setTimeout(function () {

		popupContainer.classList.add('show');

	}, 300);

	// Delay and show the real content
	setTimeout(function () {

		popupContent.classList.add('show');

	}, 800);

	// Delay and show the bullets
	setTimeout(function () {

		popupBullets.classList.add('show');

	}, 1300);

	// Delay and show the button
	setTimeout(function () {

		popupButton.classList.add('show');

	}, 1800);

	// Delay and show the image
	setTimeout(function () {

		popupImage.classList.add('show');

	}, 2300);

}

window.addEventListener("load", (event) => {

	// The popup modal
	const popup = document.getElementById("popup_alert_modal");
	if(popup === null)
		return;

	// Generate a local storage key based on the popup ID
	const popupKey = 'popup' + popup.dataset.popupid + 'exp';

	// Get the start delay and convert seconds to milliseconds
	const startdelay = parseInt(popup.dataset.startdelay) * 1000;

	// Get the restart (optional) delay and convert hours to milliseconds
	let restartdelay = 0;
	if(popup.dataset.restartdelay !== "") {
		restartdelay = parseInt(popup.dataset.restartdelay) * 3600000;
	}

	// Now now now!
	const currentTime = new Date().getTime();

	// Get the expiration timestamp (when we are allowed to restart the popup)
	let expirationTime = localStorage.getItem(popupKey);

	// If the restart delay has been set, re-enable the popup
	if(restartdelay !== 0 && expirationTime === 'disabled')
		expirationTime = null;

	// Do not show the popup if the restart delay has been disabled
	if(expirationTime === 'disabled')
		return;

	// If we have not reached/passed the expiration, do not show the popup
	if(expirationTime !== null && expirationTime >= currentTime)
		return;

	// If we reached this part, either we have no expiration, or the expiration has... expired.

	// Add an event listener for the close button
	popup.addEventListener("click", (event) => {

		if (
			event.target.closest(".close_popup_modal") ||
			!event.target.closest(".modal-container") &&
			event.target.tagName !== 'BUTTON'
		) {

			popup.classList.remove('show');
			popup.style.display = 'none';
			document.body.classList.remove('modal-open');
			if (document.querySelector('.modal-backdrop')) {
				document.querySelector('.modal-backdrop').remove();
			}

			if(restartdelay === 0) {

				// Restart delay disabled, never show popup again
				localStorage.setItem(popupKey, 'disabled');

			} else {

				// Calculate a new expiration timestamp
				expirationTime = new Date(currentTime + restartdelay);

				// Store in local storage
				localStorage.setItem(popupKey, expirationTime.getTime());

			}

		}

	});

	// Delay and show the popup
	setTimeout(function () {

		showPopupAlertModal();

	}, startdelay);

});

